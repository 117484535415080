export const createScanner = (scanSuccessCallback = Function()) => {
  window.RFID = {
    tag: null,
    scan: async (event, rfid) => {
      if (event.key === 'Enter') {
        try {
          const response = await fetch(`tags/search_by/${rfid}`)
          if (response.status === 400) {
            Modal.open('error')
            RFID.clearTag()
          } else {
            const { hash: data } = await response.json()
            RFID.attachTag(data.tag)
            scanSuccessCallback(data)
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
    unscan: event => {
      event.preventDefault()
      RFID.clearTag()
    },
    attachTag: tag => {
      const tagField = document.querySelector('#tag')
      
      if (tag) {
        tagField.value = tag.rfid
        RFID.tag = tag
    
        tagField.readOnly = true
    
        const removeButton = tagField.nextElementSibling
        removeButton.classList.add('show')
        removeButton.disabled = false
      }
    },
    clearTag: () => {
      const tagField = document.querySelector('#tag')
      tagField.readOnly = false
      tagField.value = null
      RFID.tag = null
    
      const removeButton = tagField.nextElementSibling
      removeButton.classList.remove('show')
      removeButton.disabled = true
    }
  }
};
 