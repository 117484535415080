window.Dictionaries = {
  create: async () => {
    const language = document.querySelector('#dictionary-form #language').value
    if (!language || language == '') return;

    let fileInput = document.querySelector('#dictionary-form #content');
    if (!fileInput.value.length) return;

    let body = new FormData()
    body.append('language', language)
    body.append('content', fileInput.files[0])

    Dictionaries.sendRequest(body)
  },
  sendRequest: async (body) => {
    const token = document.querySelector('meta[name="csrf-token"]').content

    try {
      const response = await fetch(`dictionaries/create`, {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token,
          'Accept': 'application/json'
        },
        body,
        credentials: 'same-origin'
      })

      if (response.status === 200) {
        alert('Upload successful. The language file is now available for the API / mobile application.')
        location.reload()
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error(error)
    }
  }
}
