const { createScanner } = require('./rfid_scanner')
const { selectOption } = require('./custom-select')
const { checkForm } = require('./tags')

function selectLanguage(language) {
  const selectWrapper = document.querySelector('#tags-container form .select-wrapper')
  selectOption(selectWrapper, language)
}

export function updateForm({ climber, admin_user }) {
  if (climber) {
    Tags.taggable = climber
    const keys = ['email', 'first_name', 'last_name']
    keys.forEach(key => {
      const field = document.querySelector(`#${key}`)
      field.value = climber[key]
    })
    selectLanguage(climber.language)
  } else if (admin_user) {
    Modal.open('admin-tag')
    RFID.clearTag()
  }

  checkForm()
}

createScanner(updateForm)
