const MicroModal = require("micromodal/dist/micromodal")

document.addEventListener('turbolinks:load', () => {
  MicroModal.init()
})

window.Modal = {
  open: type => {
    const modal = document.querySelector(`#${currentPage}-modal`)
    const variableElements = modal.querySelectorAll('[data-show-on]')
    variableElements.forEach(element => {
      if (JSON.parse(element.dataset.showOn).includes(type)) {
        element.classList.remove('hide')
      }
    })
    MicroModal.show(modal.id)
  },
  close: () => {
    const modal = document.querySelector(`#${currentPage}-modal`)
    MicroModal.close(modal.id)
    const shownElements = modal.querySelectorAll('[data-show-on]:not(.hide)')
    shownElements.forEach(element => {
      element.classList.add('hide')
    })
  }
}