window.NewsItems = {
  create: async () => {
    const thumbnailInput = document.querySelector('#news-item-form #thumbnail');
    const thumbnail = thumbnailInput.value.length ? thumbnailInput.files[0] : null;
    const titleHu = document.querySelector('#news-item-form #title-hu').value
    const titleEn = document.querySelector('#news-item-form #title-en').value
    const titleDe = document.querySelector('#news-item-form #title-de').value
    const titleFr = document.querySelector('#news-item-form #title-fr').value
    const titleEs = document.querySelector('#news-item-form #title-es').value
    const titleIt = document.querySelector('#news-item-form #title-it').value
    const featuredNews = document.querySelector('#news-item-form #featured-news').value
    const url = document.querySelector('#news-item-form #url').value

    if (!thumbnail || !titleEn || !url) {
      alert('Fields marked with * must be filled!');
      return;
    }

    let body = new FormData()
    body.append('thumbnail', thumbnail)
    body.append('title_hu', titleHu)
    body.append('title_en', titleEn)
    body.append('title_de', titleDe)
    body.append('title_fr', titleFr)
    body.append('title_es', titleEs)
    body.append('title_it', titleIt)
    body.append('featured_news', featuredNews)
    body.append('url', url)

    NewsItems.sendCreateRequest(body)
  },
  sendCreateRequest: async (body) => {
    const token = document.querySelector('meta[name="csrf-token"]').content

    try {
      const response = await fetch(`create`, {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token,
          'Accept': 'application/json'
        },
        body,
        credentials: 'same-origin'
      })

      if (response.status === 200) {
        alert('News item successfully created.')
        location.reload()
      } else {
        alert(response);
        console.log(response);
      }
    } catch (error) {
      console.error(error)
    }
  },
  update: async () => {
    const id = window.location.pathname.split('/')[3]
    const thumbnailInput = document.querySelector('#news-item-form #thumbnail');
    const thumbnail = thumbnailInput.value.length ? thumbnailInput.files[0] : null;
    const titleHu = document.querySelector('#news-item-form #title-hu').value
    const titleEn = document.querySelector('#news-item-form #title-en').value
    const titleDe = document.querySelector('#news-item-form #title-de').value
    const titleFr = document.querySelector('#news-item-form #title-fr').value
    const titleEs = document.querySelector('#news-item-form #title-es').value
    const titleIt = document.querySelector('#news-item-form #title-it').value
    const featuredNews = document.querySelector('#news-item-form #featured-news').value
    const url = document.querySelector('#news-item-form #url').value

    if (!titleEn || !url) {
      alert('Fields marked with * must be filled!');
      return;
    }

    let body = new FormData()
    body.append('id', id)
    if (thumbnail) { body.append('thumbnail', thumbnail) }
    body.append('title_hu', titleHu)
    body.append('title_en', titleEn)
    body.append('title_de', titleDe)
    body.append('title_fr', titleFr)
    body.append('title_es', titleEs)
    body.append('title_it', titleIt)
    body.append('featured_news', featuredNews)
    body.append('url', url)

    NewsItems.sendUpdateRequest(body)
  },
  sendUpdateRequest: async (body) => {
    const token = document.querySelector('meta[name="csrf-token"]').content

    try {
      const response = await fetch(`update`, {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token,
          'Accept': 'application/json'
        },
        body,
        credentials: 'same-origin'
      })

      if (response.status === 200) {
        alert('News item successfully updated.')
        location.reload()
      } else {
        alert(response);
        console.log(response);
      }
    } catch (error) {
      console.error(error)
    }
  }
}
