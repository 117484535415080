function selectOption(selectWrapper, value, defaultSelection = false) {
  const select = selectWrapper.querySelector('select')
  const fauxSelect = selectWrapper.querySelector('.faux-select')
  const fauxOptionSelector = value ? `.faux-option[data-value=${value}]` : '.faux-option'
  const fauxOption = selectWrapper.querySelector(fauxOptionSelector)

  select.value = value
  fauxSelect.textContent = fauxOption.textContent

  if (fauxOption.dataset.value && !defaultSelection) {
    fauxSelect.classList.remove('placeholder')
  } else {
    fauxSelect.classList.add('placeholder')
  }
}

document.addEventListener('turbolinks:load', () => {
  const selectWrappers = document.querySelectorAll('.select-wrapper')
  selectWrappers.forEach(selectWrapper => {
    const select = selectWrapper.querySelector('select')
    const selectArrow = selectWrapper.querySelector('.select-arrow')
    const fauxSelect = selectWrapper.querySelector('.faux-select')
    const fauxOptionsContainer = selectWrapper.querySelector('.faux-options-container')
    const fauxOptions = selectWrapper.querySelectorAll('.faux-option')

    fauxSelect.addEventListener('click', event => {
      event.stopPropagation()
      fauxSelect.classList.add('hide')
      fauxOptionsContainer.classList.add('show')
      selectArrow.classList.add('rotate-up')
    })

    fauxOptions.forEach(fauxOption => {
      fauxOption.addEventListener('click', event => {
        event.stopPropagation()
        fauxSelect.classList.remove('hide')
        fauxOptionsContainer.classList.remove('show')
        fauxSelect.textContent = fauxOption.textContent
        selectArrow.classList.remove('rotate-up')
        if (fauxOption.dataset.value) {
          fauxSelect.classList.remove('placeholder')
        } else {
          fauxSelect.classList.add('placeholder')
        }
        select.value = fauxOption.dataset.value
      })
    })

    document.addEventListener('click', () => {
      fauxSelect.classList.remove('hide')
      fauxOptionsContainer.classList.remove('show')
      selectArrow.classList.remove('rotate-up')
    })
  })
})


module.exports = {
  selectOption
}
