document.addEventListener('turbolinks:load', () => {
  const checkboxes = document.querySelectorAll('input.select-row')
  const modalTrigger = document.querySelector('button[data-micromodal-trigger="climbers-modal"]')
  let checkedCount = 0

  checkboxes.forEach(checkbox => {
    checkbox.addEventListener('change', () => {
      if (checkbox.checked) {
        checkedCount++
        modalTrigger.disabled = false
      } else {
        checkedCount--
        if (checkedCount === 0) modalTrigger.disabled = true
      }
    })
  })
})

window.Climbers = {
  selectAll: checked => {
    const checkboxes = document.querySelectorAll('input.select-row')
    const modalTrigger = document.querySelector('button[data-micromodal-trigger="climbers-modal"]')
    modalTrigger.disabled = !checked
    checkboxes.forEach(checkbox => {
      checkbox.checked = checked
    })
  },
  submit: () => {
    const form = document.querySelector('#climbers-form')
    form.submit()
  }
}
