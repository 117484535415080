document.addEventListener('turbolinks:load', () => {
  const addButton = document.querySelector('.add-button')
  const newRow = document.querySelector('.new-row')

  if (addButton && newRow) {
    addButton.addEventListener('click', () => {
      newRow.classList.remove('hide')
      addButton.classList.add('hide')
    })

    newRow.addEventListener('ajax:error', () => {
      Modal.open('email-taken')
    })
  }
})

window.AdminUsers = {
  activeId: null,
  removeNew: () => {
    const addButton = document.querySelector('.add-button')
    const newRow = document.querySelector('.new-row')

    newRow.classList.add('hide')
    addButton.classList.remove('hide')
  },
  unpair: id => {
    AdminUsers.activeId = id
    Modal.open('unpair')
  },
  delete: id => {
    AdminUsers.activeId = id
    Modal.open('delete')
  },
  sendForm: () => {
    const form = document.querySelector(`#admin-user-${AdminUsers.activeId}`)
    form.submit()
    Modal.close()
  },
  scan: async (event, rfid) => {
    if (event.key === 'Enter') {
      try {
        const [, id] = event.target.id.split('tag-')
        const token = document.querySelector('meta[name="csrf-token"]').content
        const body = JSON.stringify({ rfid })
        const response = await fetch(`admin_users/${id}/pair`, {
          method: 'PATCH',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body,
          credentials: 'same-origin'
        })
        if (response.status >= 400) {
          console.log(response)
          const { error } = await response.json()
          Modal.open(error || 'error')
          event.target.value = ''
        } else {
          location.reload()
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  unscan: event => {
    event.preventDefault()
  }
}
