const { selectOption } = require('./custom-select')
const { updateForm } = require('./create_climber_scanner')

function getClimberData() {
  const fields = ['email', 'first_name', 'last_name', 'language']
  return fields.reduce((climber, field) => {
    const input = document.querySelector(`#${field}`)
    climber[field] = input.value
    return climber
  }, {})
}

export function checkForm() {
  const inputs = Array.from(document.querySelectorAll('#tag-form input, #tag-form select'))

  const formIsEmpty = inputs.every(input => !input.value)
  const emailIsEntered = inputs.find(input => input.id === 'email' && input.value)
  const tagIsPairable = emailIsEntered && RFID.tag && !RFID.tag.taggable_id

  const cancelButton = document.querySelector('#tag-form .cancel-button')
  const pairButton = document.querySelector('#tag-form .form-actions .primary-button')

  const tagIsUnpairable = RFID.tag && Tags.taggable && RFID.tag.taggable_id === Tags.taggable.id
  const unpairButton = document.querySelector('#tag-form .unpair-button')
  if (tagIsUnpairable) {
    unpairButton.classList.add('show')
  } else {
    unpairButton.classList.remove('show')
  }

  cancelButton.disabled = formIsEmpty
  pairButton.disabled = !tagIsPairable
}

function clearAutocompletes() {
  const autocompleteContainers = document.querySelectorAll('.autocomplete-container')
  autocompleteContainers.forEach(autocomplete => {
    displayAutocompleteResults(autocomplete, [])
    const input = autocomplete.querySelector('.autocomplete-input p')
    input.textContent = null
  })
}

function resetFormButtons() {
  const formButtons = document.querySelectorAll('#tag-form .form-actions button')
  formButtons.forEach(formButton => {
    formButton.disabled = true
  })
}

function selectLanguage(language, { defaultSelection = false } = {}) {
  const selectWrapper = document.querySelector('#tags-container form .select-wrapper')
  selectOption(selectWrapper, language, defaultSelection)
}

function displayAutocompleteResults(container, results, primaryKey, secondaryKey) {
  const resultsContainer = container.querySelector('.autocomplete-results')
  resultsContainer.innerHTML = ''

  results.forEach(result => {
    const resultContainer = document.createElement('div')
    resultContainer.classList.add('result-container')
    resultContainer.innerHTML = `
      <p>${result[primaryKey]}</p>
      <p>${result[secondaryKey]}</p>
    `
    resultContainer.addEventListener('click', () => {
      const { id, email, first_name, last_name, language, tag } = result
      updateForm({ tag, climber: { id, email, first_name, last_name, language } })
    })

    resultsContainer.appendChild(resultContainer)
  })
}

function closeOpenAutocomplete({ target }) {
  const inputClasses = ['autocomplete-input', 'autocomplete-input-text', 'caret']
  if (inputClasses.includes(target.className)) return

  const autocompleteContainer = document.querySelector('.autocomplete-container:not(.hide)')
  if (autocompleteContainer) {
    autocompleteContainer.classList.add('hide')
  }
}

document.addEventListener('turbolinks:load', () => {
  document.addEventListener('click', closeOpenAutocomplete)
  document.addEventListener('keyup', event => {
    if (event.key === 'Tab') {
      const fields = ['email', 'first_name', 'last_name']
      if (fields.find(field => field === event.target.id)) {
        Tags.openInput(null, event.target.id)
      } else {
        closeOpenAutocomplete(event)
      }
    }
  })
})

window.Tags = {
  taggable: null,
  openInput: (event, inputId) => {
    if (event) event.stopPropagation()

    const openContainer = document.querySelector('.autocomplete-container:not(.hide)')
    if (openContainer && openContainer.dataset.id !== inputId) openContainer.classList.add('hide')

    const autocompleteContainer = document.querySelector(`.autocomplete-container[data-id=${inputId}]`)
    autocompleteContainer.classList.remove('hide')
  },
  changeInput: async input => {
    checkForm()

    const { id, value } = input
    const autocompleteContainer = document.querySelector(`.autocomplete-container[data-id=${id}]`)
    const autocompleteInput = autocompleteContainer.querySelector('.autocomplete-input p')
    autocompleteInput.textContent = value
    if (value) {
      try {
        const key = id === 'email' ? 'email' : 'name'
        const response = await fetch(`climbers/search_by?${key}=${value}&priority=${id}`)
        const { list } = await response.json()

        const secondaryKey = key === 'email' ? 'name' : 'email'
        displayAutocompleteResults(autocompleteContainer, list, key, secondaryKey)
      } catch (error) {
        console.error(error)
      }
    } else displayAutocompleteResults(autocompleteContainer, [])
  },
  clearForm: () => {
    const form = document.querySelector('#tag-form')

    form.reset()
    selectLanguage(current_admin_user.language, { defaultSelection: true })
    RFID.clearTag()
    resetFormButtons()
    clearAutocompletes()
    Tags.taggable = null
  },
  pair: async () => {
    const token = document.querySelector('meta[name="csrf-token"]').content
    const body = JSON.stringify(
      Tags.taggable
        ? { 'climber_id': Tags.taggable.id }
        : { climber: getClimberData() }
    )

    try {
      const response = await fetch(`tags/${RFID.tag.rfid}/pair`, {
        method: 'PATCH',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body,
        credentials: 'same-origin'
      })

      if (response.status === 200) {
        Modal.open('pair')
        Tags.clearForm()
      } else {
        // TODO: Show form errors
      }
    } catch (error) {
      console.error(error)
    }
  },
  unpair: async () => {
    if (RFID.tag && Tags.taggable) {
      const token = document.querySelector('meta[name="csrf-token"]').content

      try {
        const response = await fetch('tags/unpair', {
          method: 'PATCH',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            tag: {
              rfid: RFID.tag.rfid,
              taggable_id: Tags.taggable.id
            }
          }),
          credentials: 'same-origin'
        })
        if (response.status === 200) {
          const { hash: data } = await response.json()
          Tags.clearForm()
          updateForm(data)
        } else {
          console.error('Mismatching tag and taggable.')
        }
      } catch (error) {
        console.error(error)
      }
    }
    Modal.close()
  }
}
