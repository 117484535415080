window.ChallengeEvents = {
  create: async () => {
    const cover = document.querySelector('#challenge-event-form #cover').value
    const coverDynamicInput = document.querySelector('#challenge-event-form #cover-dynamic');
    const coverDynamic = coverDynamicInput.value.length ? coverDynamicInput.files[0] : null;
    const icon = document.querySelector('#challenge-event-form #icon').value
    const iconDynamicInput = document.querySelector('#challenge-event-form #icon-dynamic');
    const iconDynamic = iconDynamicInput.value.length ? iconDynamicInput.files[0] : null;
    const awardIconDynamicInput = document.querySelector('#challenge-event-form #award-icon-dynamic');
    const awardIconDynamic = awardIconDynamicInput.value.length ? awardIconDynamicInput.files[0] : null;
    const awardIconInactiveDynamicInput = document.querySelector('#challenge-event-form #award-icon-inactive-dynamic');
    const awardIconInactiveDynamic = awardIconInactiveDynamicInput.value.length ? awardIconInactiveDynamicInput.files[0] : null;
    const titleHu = document.querySelector('#challenge-event-form #title-hu').value
    const titleEn = document.querySelector('#challenge-event-form #title-en').value
    const titleDe = document.querySelector('#challenge-event-form #title-de').value
    const titleFr = document.querySelector('#challenge-event-form #title-fr').value
    const titleEs = document.querySelector('#challenge-event-form #title-es').value
    const titleIt = document.querySelector('#challenge-event-form #title-it').value
    const subtitleHu = document.querySelector('#challenge-event-form #subtitle-hu').value
    const subtitleEn = document.querySelector('#challenge-event-form #subtitle-en').value
    const subtitleDe = document.querySelector('#challenge-event-form #subtitle-de').value
    const subtitleFr = document.querySelector('#challenge-event-form #subtitle-fr').value
    const subtitleEs = document.querySelector('#challenge-event-form #subtitle-es').value
    const subtitleIt = document.querySelector('#challenge-event-form #subtitle-it').value
    const descriptionHu = document.querySelector('#challenge-event-form #description-hu').value
    const descriptionEn = document.querySelector('#challenge-event-form #description-en').value
    const descriptionDe = document.querySelector('#challenge-event-form #description-de').value
    const descriptionFr = document.querySelector('#challenge-event-form #description-fr').value
    const descriptionEs = document.querySelector('#challenge-event-form #description-es').value
    const descriptionIt = document.querySelector('#challenge-event-form #description-it').value
    const goal = document.querySelector('#challenge-event-form #goal').value
    const rewardsDescriptionHu = document.querySelector('#challenge-event-form #rewards-description-hu').value
    const rewardsDescriptionEn = document.querySelector('#challenge-event-form #rewards-description-en').value
    const rewardsDescriptionDe = document.querySelector('#challenge-event-form #rewards-description-de').value
    const rewardsDescriptionFr = document.querySelector('#challenge-event-form #rewards-description-fr').value
    const rewardsDescriptionEs = document.querySelector('#challenge-event-form #rewards-description-es').value
    const rewardsDescriptionIt = document.querySelector('#challenge-event-form #rewards-description-it').value
    const startDate = document.querySelector('#challenge-event-form #start-date').value
    const endDate = document.querySelector('#challenge-event-form #end-date').value
    const challengeType = document.querySelector('#challenge-event-form #challenge-type').value
    const joinPrice = document.querySelector('#challenge-event-form #join-price').value
    const roomId = document.querySelector('#challenge-event-form #room-id').value

    if (!cover || !coverDynamic || !icon || !iconDynamic || !awardIconDynamic || !awardIconInactiveDynamic ||
        !titleEn || !subtitleEn || !descriptionEn || !goal || !rewardsDescriptionEn || !startDate ||
        !endDate || !challengeType || !joinPrice) {
      alert('Fields marked with * must be filled!');
      return;
    }

    let body = new FormData()
    body.append('cover', cover)
    body.append('cover_dynamic', coverDynamic)
    body.append('icon', icon)
    body.append('icon_dynamic', iconDynamic)
    body.append('award_icon_dynamic', awardIconDynamic)
    body.append('award_icon_inactive_dynamic', awardIconInactiveDynamic)
    body.append('title_hu', titleHu)
    body.append('title_en', titleEn)
    body.append('title_de', titleDe)
    body.append('title_fr', titleFr)
    body.append('title_es', titleEs)
    body.append('title_it', titleIt)
    body.append('subtitle_hu', subtitleHu)
    body.append('subtitle_en', subtitleEn)
    body.append('subtitle_de', subtitleDe)
    body.append('subtitle_fr', subtitleFr)
    body.append('subtitle_es', subtitleEs)
    body.append('subtitle_it', subtitleIt)
    body.append('description_hu', descriptionHu)
    body.append('description_en', descriptionEn)
    body.append('description_de', descriptionDe)
    body.append('description_fr', descriptionFr)
    body.append('description_es', descriptionEs)
    body.append('description_it', descriptionIt)
    body.append('goal', goal)
    body.append('rewards_description_hu', rewardsDescriptionHu)
    body.append('rewards_description_en', rewardsDescriptionEn)
    body.append('rewards_description_de', rewardsDescriptionDe)
    body.append('rewards_description_fr', rewardsDescriptionFr)
    body.append('rewards_description_es', rewardsDescriptionEs)
    body.append('rewards_description_it', rewardsDescriptionIt)
    body.append('start_date', startDate)
    body.append('end_date', endDate)
    body.append('challenge_type', challengeType)
    body.append('join_price', joinPrice)
    body.append('room_id', roomId)

    ChallengeEvents.sendCreateRequest(body)
  },
  sendCreateRequest: async (body) => {
    const token = document.querySelector('meta[name="csrf-token"]').content

    try {
      const response = await fetch(`create`, {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token,
          'Accept': 'application/json'
        },
        body,
        credentials: 'same-origin'
      })

      if (response.status === 200) {
        alert('Challenge successfully created.')
        location.reload()
      } else {
        alert(response);
        console.log(response);
      }
    } catch (error) {
      console.error(error)
    }
  },
  update: async () => {
    const id = window.location.pathname.split('/')[3]
    const cover = document.querySelector('#challenge-event-form #cover').value
    const coverDynamicInput = document.querySelector('#challenge-event-form #cover-dynamic');
    const coverDynamic = coverDynamicInput.value.length ? coverDynamicInput.files[0] : null;
    const icon = document.querySelector('#challenge-event-form #icon').value
    const iconDynamicInput = document.querySelector('#challenge-event-form #icon-dynamic');
    const iconDynamic = iconDynamicInput.value.length ? iconDynamicInput.files[0] : null;
    const awardIconDynamicInput = document.querySelector('#challenge-event-form #award-icon-dynamic');
    const awardIconDynamic = awardIconDynamicInput.value.length ? awardIconDynamicInput.files[0] : null;
    const awardIconInactiveDynamicInput = document.querySelector('#challenge-event-form #award-icon-inactive-dynamic');
    const awardIconInactiveDynamic = awardIconInactiveDynamicInput.value.length ? awardIconInactiveDynamicInput.files[0] : null;
    const titleHu = document.querySelector('#challenge-event-form #title-hu').value
    const titleEn = document.querySelector('#challenge-event-form #title-en').value
    const titleDe = document.querySelector('#challenge-event-form #title-de').value
    const titleFr = document.querySelector('#challenge-event-form #title-fr').value
    const titleEs = document.querySelector('#challenge-event-form #title-es').value
    const titleIt = document.querySelector('#challenge-event-form #title-it').value
    const subtitleHu = document.querySelector('#challenge-event-form #subtitle-hu').value
    const subtitleEn = document.querySelector('#challenge-event-form #subtitle-en').value
    const subtitleDe = document.querySelector('#challenge-event-form #subtitle-de').value
    const subtitleFr = document.querySelector('#challenge-event-form #subtitle-fr').value
    const subtitleEs = document.querySelector('#challenge-event-form #subtitle-es').value
    const subtitleIt = document.querySelector('#challenge-event-form #subtitle-it').value
    const descriptionHu = document.querySelector('#challenge-event-form #description-hu').value
    const descriptionEn = document.querySelector('#challenge-event-form #description-en').value
    const descriptionDe = document.querySelector('#challenge-event-form #description-de').value
    const descriptionFr = document.querySelector('#challenge-event-form #description-fr').value
    const descriptionEs = document.querySelector('#challenge-event-form #description-es').value
    const descriptionIt = document.querySelector('#challenge-event-form #description-it').value
    const goal = document.querySelector('#challenge-event-form #goal').value
    const rewardsDescriptionHu = document.querySelector('#challenge-event-form #rewards-description-hu').value
    const rewardsDescriptionEn = document.querySelector('#challenge-event-form #rewards-description-en').value
    const rewardsDescriptionDe = document.querySelector('#challenge-event-form #rewards-description-de').value
    const rewardsDescriptionFr = document.querySelector('#challenge-event-form #rewards-description-fr').value
    const rewardsDescriptionEs = document.querySelector('#challenge-event-form #rewards-description-es').value
    const rewardsDescriptionIt = document.querySelector('#challenge-event-form #rewards-description-it').value
    const startDate = document.querySelector('#challenge-event-form #start-date').value
    const endDate = document.querySelector('#challenge-event-form #end-date').value
    const challengeType = document.querySelector('#challenge-event-form #challenge-type').value
    const joinPrice = document.querySelector('#challenge-event-form #join-price').value
    const roomId = document.querySelector('#challenge-event-form #room-id').value

    if (!cover || !icon || !titleEn || !subtitleEn || !descriptionEn || !goal ||
        !rewardsDescriptionEn || !startDate || !endDate || !challengeType || !joinPrice) {
      alert('Fields marked with * must be filled!');
      return;
    }

    let body = new FormData()
    body.append('id', id)
    body.append('cover', cover)
    if (coverDynamic) { body.append('cover_dynamic', coverDynamic) }
    body.append('icon', icon)
    if (iconDynamic) { body.append('icon_dynamic', iconDynamic) }
    if (awardIconDynamic) { body.append('award_icon_dynamic', awardIconDynamic) }
    if (awardIconInactiveDynamic) { body.append('award_icon_inactive_dynamic', awardIconInactiveDynamic) }
    body.append('title_hu', titleHu)
    body.append('title_en', titleEn)
    body.append('title_de', titleDe)
    body.append('title_fr', titleFr)
    body.append('title_es', titleEs)
    body.append('title_it', titleIt)
    body.append('subtitle_hu', subtitleHu)
    body.append('subtitle_en', subtitleEn)
    body.append('subtitle_de', subtitleDe)
    body.append('subtitle_fr', subtitleFr)
    body.append('subtitle_es', subtitleEs)
    body.append('subtitle_it', subtitleIt)
    body.append('description_hu', descriptionHu)
    body.append('description_en', descriptionEn)
    body.append('description_de', descriptionDe)
    body.append('description_fr', descriptionFr)
    body.append('description_es', descriptionEs)
    body.append('description_it', descriptionIt)
    body.append('goal', goal)
    body.append('rewards_description_hu', rewardsDescriptionHu)
    body.append('rewards_description_en', rewardsDescriptionEn)
    body.append('rewards_description_de', rewardsDescriptionDe)
    body.append('rewards_description_fr', rewardsDescriptionFr)
    body.append('rewards_description_es', rewardsDescriptionEs)
    body.append('rewards_description_it', rewardsDescriptionIt)
    body.append('start_date', startDate)
    body.append('end_date', endDate)
    body.append('challenge_type', challengeType)
    body.append('join_price', joinPrice)
    body.append('room_id', roomId)

    ChallengeEvents.sendUpdateRequest(body)
  },
  sendUpdateRequest: async (body) => {
    const token = document.querySelector('meta[name="csrf-token"]').content

    try {
      const response = await fetch(`update`, {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token,
          'Accept': 'application/json'
        },
        body,
        credentials: 'same-origin'
      })

      if (response.status === 200) {
        alert('Challenge successfully updated.')
        location.reload()
      } else {
        alert(response);
        console.log(response);
      }
    } catch (error) {
      console.error(error)
    }
  }
}
