const Selectr = require("mobius1-selectr/dist/selectr.min.js")

function selectRadioButton(radioButtons, radioButton) {
  radioButtons.forEach(button => button.classList.remove('checked'))
  radioButton.classList.add('checked')

  const realRadio = document.querySelector(`#${radioButton.dataset.radioId}`)
  realRadio.checked = true

  Notifications.checkValidity()
}

document.addEventListener('turbolinks:load', () => {
  const usersSelect = document.querySelector('#users')

  if (usersSelect) {
    const selectr = new Selectr(usersSelect, {
      multiple: true,
      placeholder: '...'
    })

    selectr.on('selectr.select', () => {
      const radioButton = document.querySelector(`[data-radio-id=recipients_select]`)
      selectRadioButton(radioButtons, radioButton)
    })

    selectr.on('selectr.deselect', () => {
      if (selectr.getValue().length) return;

      const radioButton = document.querySelector(`[data-radio-id=recipients_all]`)
      selectRadioButton(radioButtons, radioButton)
    })
  }

  const radioButtons = document.querySelectorAll('.radio-button')
  const radioLabels = document.querySelectorAll('.radio-label')

  radioButtons.forEach(radioButton => {
    radioButton.addEventListener('click', () => {
      selectRadioButton(radioButtons, radioButton)
    })
  })

  radioLabels.forEach(radioLabel => {
    radioLabel.addEventListener('click', () => {
      const radioButton = document.querySelector(`[data-radio-id=${radioLabel.htmlFor}]`)
      selectRadioButton(radioButtons, radioButton)
    })
  })
})

function isRecipientsValid() {
  const radio = document.querySelector('input[name=recipients]:checked')
  if (radio.value === 'select') {
    return document.querySelectorAll('#users option[selected]').length
  }

  return true
}

function isMessageValid() {
  const subject = document.querySelector('#subject')
  const message = document.querySelector('#message')

  return subject.value.length && message.value.length
}

window.Notifications = {
  checkValidity: () => {
    const submitButton = document.querySelector('form button[type=submit]')
    submitButton.disabled = !isMessageValid() || !isRecipientsValid()
  },
  updateCount: field => {
    const countElement = document.querySelector(`.character-count[data-field-id='${field.id}']`)
    countElement.textContent = field.value.length
  }
}
