const Chart = require("chart.js/dist/Chart")

Chart.defaults.global.defaultFontFamily = 'Open Sans';

document.insertHtml = function(id, html)
{
   var ele = document.getElementById(id);
   ele.innerHTML = html;
   var codes = ele.getElementsByTagName("script");
   for(var i=0;i<codes.length;i++)
   {
       eval(codes[i].text);
   }
}
